import * as React from 'react'
import { Box } from 'grid-styled'
import styled from 'styled-components'
import { Button } from './Button'
import Input, { Textarea } from './Input'
import { yPad, xPad } from '../utils/theme'
import Form from './Form'

interface IContainerProps {
  noBorder: boolean
}

export const Container = styled<IContainerProps, any>('div')`
  ${yPad};
  ${xPad};
  border-top: ${(props) =>
    props.noBorder ? 'none' : `1px solid ${props.theme.colors.primary}`};
  display: flex;
  justify-content: center;
  width: 80%;
  margin: 30px auto;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

const Information = styled.div`
  flex-basis: 25%;
  margin-right: 80px;

  @media (max-width: 600px) {
    margin-right: 0;
    margin-bottom: 2rem;
  }
  h2 {
    margin-bottom: 2rem;
    font-weight: 400;
    color: ${(props) => props.theme.colors.primary};
  }
  span {
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: 3px;
  }
  p {
    font-size: 1.3rem;
    margin-top: 1.3rem;
    line-height: 1.4;
  }
`

interface Props {
  children?: React.ReactChild
  title?: string
  showMessage?: boolean
  id?: string
  type?: 'audit'
  noBorder?: boolean
}

export default class extends React.Component<Props> {
  render() {
    return (
      <Container {...this.props}>
        <Information>
          <h2>Phone</h2>
          <span>0419 236 597</span>
          <p>
            Upright Performance
            <br />
            <br />
            5 Windsor Rd, Red Hill QLD 4059
          </p>

          {/* <p style={{ fontSize: 16, marginTop: '1.5rem' }}>
            <strong>Office Hours</strong>
          </p>
          <p style={{ fontSize: 16, marginTop: '0.5rem' }}>
            Monday - Friday: 9am-5pm
            <br />
            Home based appointments available
          </p> */}
        </Information>
        <Form {...this.props} />
      </Container>
    )
  }
}
